/*
 * This code comes straight from https://docs.djangoproject.com/en/dev/ref/contrib/csrf/#ajax
 * It makes sure that any AJAX POSTs get the csrf token added to them. It should be used
 * at the top of our base.html file
 */

(function($) {

    var setup = function() {
        var csrftoken = $.cookie('csrftoken');
        function csrfSafeMethod(method) {
            // these HTTP methods do not require CSRF protection
            return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
        }
        $.ajaxSetup({
            crossDomain: false, // obviates need for sameOrigin test
            beforeSend: function(xhr, settings) {
                if (!csrfSafeMethod(settings.type)) {
                    xhr.setRequestHeader("X-CSRFToken", csrftoken);
                }
            }
        });
    };

    $(function () {
        setup();
    });

})(jQuery);
